@import "sass-lib";
@media (min-width: 0px) and (max-width: 1028px) {

	header{
		.overlay{
	         position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0,0,0,0.8); display: none; z-index:98;
	    }
	    .menumobile{
			width: 100%; height: 100vh; background: #1f295c; position: fixed; top: 0; right: 0; padding: 150px 30px 80px; @include boxSizing(border-box);
			z-index: 999; display: none; overflow: auto;
			nav{ 
				text-align: center;
				ul{
					> li{
						font-size: 32px; font-weight: 700; margin-bottom: 30px; text-transform: uppercase;
						> a{  color: #fff; }
						ul{
							display: none;
							> li{
								font-size: 22px; margin-top: 15px;  text-transform: none; margin-bottom: 10px;
								a{ border: none; }
							}
						}
					}
				}
			}
			&:before{ content: ''; width: 100%; height: 100px; position: fixed; left: 0; top: 0; right: 0; background: #fff; }
		}
		.left-header{
			position: relative; z-index: 10002;
		}
		.right-header{
			position: relative; z-index: 10002;
			nav{ display: none; }
			.togglemenu{
	            float: right; width: 30px; height:30px; position: relative; cursor: pointer; z-index: 10001; margin-top:35px;
	            span{ 
	                width: 100%; height: 3px; background: #000; display: block; position: absolute;
	                @include transition(0.2s ease-out all);
	                &:nth-child(1){ top: 0; width: 60%; right: 0; }
	                &:nth-child(2){ top: 10px;  }
	                &:nth-child(3){ top: 20px; width: 60%; left: 0; }
	            }
	            &.close{
	                span{  
	                    background: #000;
	                    &:nth-child(1){ top: 12px; @include transform(rotate(45deg)); width: 100%; }
	                    &:nth-child(2){ opacity: 0; }
	                    &:nth-child(3){ top: 12px; @include transform(rotate(-45deg)); width: 100%; }
	                }
	            }
	        }       
		}
		.lang{ margin-right: 25px; }
	}
	.wrap-content:before{ left: -61px; }
	.home.news{
		.btn-detail{ left:15px; right: 15px;  }
		.box-news{
			.wrap-news{
				.list-news{padding: 0 15px; }
			}
		}
	}
	
}

@media (min-width: 0px) and (max-width: 1015px) {
	header{
		height: 60px;
		.left-header{
			img{ width: 125px; }
		}
		.right-header{
	  		.search{ line-height: 60px; }	
	  		.lang{
	        	margin-right: 20px; 
	        	span{ line-height: 60px; }
	        }

	        .togglemenu{ margin-top: 20px; }
	  	}
	  	.menumobile{
	  		padding-top: 118px;
	  		&:before{ height:60px; }
	  	}
	}
	.wrapper{ width: auto; margin:0 40px; }
	.wrap-content{
		margin-top: 0; padding-top: 40px;
		&:before{ display:none; }
	}
	.wrapper-head{ width: auto; margin: 0 20px; }
	
	.home{
		.slider-home{
			margin-top: 60px;
			.desc{ width: 690px;}
			h2{ font-size: 55px; }
			h4{ font-size: 20px; line-height: 24px; }
		}
		&.service{
			.left{
				float: none; display: block; width: 100%;
			}
			.right{ float: none;display: block; width: 100%; }
			.box-service{
				margin: 0 -20px;
				.list-service{ 
					width: 50%;display: block; margin-bottom: 40px; float: left; min-height: 260px;
				}
			}
		}
		&.contact{
			.bg{ display: none; }
		}
		&.product{
			overflow: hidden;
			.wrapper{}
			.box{
				margin: 0 -15% 0 0; display: block;
				.list-product{
					padding: 0 10px; width: 270px;
					.images{ @include borderRadius(6px); }
					.capt{
						left: 10px; top: 20px;
						h4{ font-size: 22px; margin-bottom: 10px;}
						small{ line-height: 14px; height: 28px; overflow: hidden; display: block; }
					}
				}
			}
		}
		&.news{
			overflow: hidden;
			.box-news{
				.wrap-news{
					width: 70%; margin: 0 -15% 0 0;
					.list-news{ width: 240px; padding: 0 10px; }
				}
				.desc-new{ width: 35%; }
			}
		}
		&.qoutes{
			padding: 90px 0;
			.txt{
				i{ font-size: 16px; font-style: normal;}
			}
			.profile{
				margin-right: 8%;
			}
			.nav_info{
				right: 4%;
				span{ font-size: 12px; }
			}
			.arr-right{ right: 10%; background-size: 100%;  }
		}
		&.contact{
			padding: 90px 0;
			.wrap{ width: 100%; }
			.wrap-contact{ width: auto; }
		}

	}
	.btn{ font-size: 13px; }
	.btn-categ{
		font-size: 12px; line-height: 20px; padding: 0 10px;
	}
	.btn-rounded{ 
		width: 30px; height: 30px; 
		&:before{ background-size:100%; width: 6px; }
	}
	.middle{
		padding: 60px 0;
		h3{ font-size: 25px; margin-bottom: 15px;  }
		.banner-std{
			.qts{
				top: 30%;
				span{ font-size:38px;  }
				.wrapper{ width: auto; margin:0 20px; }
			}
		}
		.wrapper{ width: auto; margin:0; padding: 0; }
		.container{ width: auto; margin: 0 20px; }
		.images-middle{
			margin: 0 0 20px;
		}
		.middle-ul{
			ul{
				li{ 
					font-size: 16px;  margin-bottom: 10px; font-weight: 500;
					&:before{ width:5px; height: 5px; }
				}
			}
		}

	}
	.tittle-section{
		p{ width: auto; }
	}
	.box-leftright{
		.box{
			margin-bottom: 50px;
			.txt{ padding-top: 0; }
			i{ font-size:13px; line-height: 20px;  font-weight: 500;}
			p{ font-size: 13px; line-height: 20px; font-weight: 500; }
		}	
	}
	.desc-sys{ 
		margin: 30px -20px; padding: 80px 20px;
		&:before{ display: none; }
	}
	.tittle-middle{
		h3{ font-size: 30px; line-height: 32px;}
		&:before{ left:0; top: -25px; }
	}
	.sign{ margin-top: 50px; }
	.tittle-middle{
		.left{ float: none; display: block; width: 100%; margin-bottom: 40px;}
		.right{ float: none; display: block; width: 100%; }
	}
	.search-result{
		input[type='text']{ font-size: 35px; height: 55px; }
		.input{
			input[type='submit']{ width: 35px ; height: 35px; }
		}
	}
	.paging{
		margin: 40px auto 0;
		.arr{ padding: 0 15px;font-size: 13px; }
		a{ margin: 0; line-height: 30px; padding: 0 15px;  }
	}
	.under-con{
		.logo{ left: 2%; top: 5%; }
        .wrapper{ width: auto; margin: 0 20px; }
        h1{ font-size: 40px; }
        h3{ font-size: 20px; }
        .wrapper{ 
            padding-top: 180px;
            .text-content{ 
                width: 400px; 
                h1{ line-height: 50px; }
            }
            .form{
                input, textarea{ width: 300px; }
               .btn{ line-height: 50px; padding: 0 30px; }
            }
        }
    }
	footer{
		h5{ font-size:16px;  }
		.top{
			padding-bottom: 40px;
			.left{
				.box{ 
					width: 38%; margin-bottom: 30px;
				 	&:nth-child(even){ margin-right: 0; }
				 }
			}
		}
	}
	.home.news .box-news .wrap-news .slick-arrow.slick-prev{ display: none; }
	.news-event{
		.abslt{ top: 10%; left: 20px; right: 20px; }
		h2{ height: 100px;  }
	}
}